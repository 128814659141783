#gadgetSlider {
    border-radius: '1rem' !important;
}

.thumb {
    display: none !important;
}

.enzi-button {
    border: '2px solid #db7f3b';
    border-radius: 5px;
    color: '#202a44';
    margin-bottom: '1rem';
    margin-right: '1rem';
    padding-left: '2rem';
    padding-right: '2rem';
    padding-bottom: '0.5rem';
    padding-top: '0.5rem';
    font-variant: small-caps;
    width: '10rem';
    font-family: 'Nunito';
    font-weight: 700;
    font-size: '0.5rem' !important;
}

.enzi-button:hover {
    background-color: #db7f3b;
}

.gadget {
    position: relative;
    width: 100%;
}

.gadget-image {
    display: block;
    width: 100%;
    text-align: center;
    height: auto;
  }

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #db7f3b;
  }

.gadget:hover .overlay{
    opacity: 0.7;
}