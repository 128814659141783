.sign-up-botton {
    border: '2px solid #db7f3b';
    border-radius: '5px';
    color: '#db7f3b';
    margin-bottom: '1rem';
    margin-right: '1rem';
    font-variant: 'all-small-caps';
    width: '10rem';
    font-family: 'Nunito';
    font-weight: '700';
    font-size: '1.2rem';
    padding-left: '2rem';
    padding-right: '2rem';
    padding-top: '0.5rem';
    padding-bottom: '0.5rem';
}

#sign-up-botton:hover{
    border: '2px solid #202a44' !important;
    background-color: white !important;
}