.title {
    font-family: "Nunito";
    font-weight: '700' !important;
    color: '#202a44'
}

#appView .powrMark {
    font-size: .9rem;
    font-family: gordita;
    position: relative;
    cursor: pointer;
    display: none !important;
}

#appView .powrMark {
    display: none !important;
}